import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=49df1306&scoped=true"
import script from "./Profile.vue?vue&type=script&lang=js"
export * from "./Profile.vue?vue&type=script&lang=js"
import style0 from "./Profile.vue?vue&type=style&index=0&id=49df1306&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49df1306",
  null
  
)

export default component.exports